/**
 * Module: Homepage Impact Area
 */

const initHomeImpactArea = () => {
  if ($('.s-home_impact_area').length) {

    let heading = $('.s-home_impact_area').find('.float');
    let programs = $('.s-home_impact_area').find('.programs');
    let peekValue = 45;

    const glide = new Glide('.s-home_impact_area .glide', {
      peek: {
        before: peekValue,
        after: peekValue
      }
    });

    glide.on(['mount.before', 'run'], (e) => {
      let currentIndex = glide.index + 1;
      let totalNodes = $('.s-home_impact_area .glide .glide__slide').length;

      gsap.to('.s-home_impact_area .mobile .progress .bar', 0.15, {
        scaleX: currentIndex / totalNodes,
        ease: 'power3.out'
      });
    });

    glide.mount();

    resizeMargin(programs, heading);

    $(window).on('resize', () => {
      resizeMargin(programs, heading);
    });

  }
}