/**
 * newsAjax.js
 * @author Cody Marcoux (Studio123)
 */

const initNews = () => {
  if ($('.s-news_listing').length) {
    $('a.view-more').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      $(this).addClass('active');

      let args = JSON.stringify($(this).data('query'));
      let paged = parseInt($(this).attr('data-page'));
      let maxPages = parseInt($(this).attr('data-max-pages'));

      newsQuery(args, paged, maxPages, $(this));
      $(this).attr('data-page', paged + 1);
    });
  }
}

const newsQuery = (args, paged, maxPages, buttonElem) => {
  $.ajax({
    url: '/wp-admin/admin-ajax.php',
    type: 'POST',
    data: {
      action: 'post_query',
      args: args,
      paged: paged
    },
  }).done(function(data) {
    $('.posts').append(data);

    buttonElem.removeClass('active');

    if (maxPages <= paged) {
      buttonElem.remove();
    }
  });
}