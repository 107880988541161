/**
 * scripts.js
 * @author Cody Marcoux (Studio123)
 * @description Misc. functions and initializations
 */

const init = () => {
  initRellax();
  initHomeImpactArea();
  initForms();
  initNews();
  // let dots = new Dots();
}

init();

// Navigation states
$('header nav.desktop li a').each((i, e) => {
  $(e).parent('li').attr('data-page-id', $(e).attr('data-page-id'));
});

$('header a').on('click', (e) => {
  let parentItem = $(e.currentTarget).parent('li');
  let id = parentItem.attr('data-page-id');

  checkCurrentPage(id);
});

const checkCurrentPage = (id) => {
  if (!id) {
    id = $('.main-container').attr('data-post-id');
  }

  let navElement = $('header nav.desktop li[data-page-id="' + id + '"]');
  $('header nav.desktop li')
    .removeClass('current-menu-item')
    .removeClass('current-page-ancestor');

  if (id) {
    $('header nav.desktop li[data-page-id="' + id + '"]')
      .addClass('current-menu-item');
  }

  if (navElement.length && navElement.parents('li').hasClass('menu-item-has-children')) {
    navElement.parents('li')
      .addClass('current-page-ancestor');
  }
}

if (window.barba) {

  barba.hooks.before((data) => {
    $('.page-container').addClass('barba-animating');
  });

  barba.hooks.beforeEnter((data) => {
    let nextContainer = data.next.container;
    let pageId = $(nextContainer).attr('data-post-id');
    checkCurrentPage(pageId);
  });

  barba.hooks.enter((data) => {
    init();
  });

  barba.hooks.afterEnter((data) => {

  });

  barba.hooks.after((data) => {
    $('.page-container')
      .removeClass('barba-animating');
  });

}

// Init Barba
if (window.barba) {
  // ! Disabled Barba for now due to complications with forms working after barba page transitions
  // ! Do not enable until form issues are fixed !

  // barba.init({
  //   debug: true,
  //   prefetchIgnore: false,
  //   logLevel: 3,
  //   timeout: 5000,
  //   transitions: fadeTransition
  // });
} else {
  console.warn('Barba is not found. The site will work normally, but AJAX navigation is disabled.');
}