/**
 * Module: Floating Dots
 */

let rellax;

// Init Rellax
const initRellax = () => {
  // Destroy old instances
  if (rellax && (typeof rellax.destroy === "function")) {
    rellax.destroy();
  }

  // Create new instances if required
  if ($('.rellax').length) {
    rellax = new Rellax('.rellax', {
      center: true
    });
  }
}