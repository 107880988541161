/**
 * mobileMenu.js
 * @author: Cody Marcoux (Studio123)
 */

let menuButton = $('.menu-button');
let menuDuration = 1;
let menuIsAnimating = false;

let clipClosed = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';
let clipOpen = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';

const mainMenuOpen = gsap
  .timeline({
    paused: true,
    onStart: () => {
      $('.menu-button, nav.mobile').addClass('is-active');
      $('body').css('overflow', 'hidden');
      menuIsAnimating = true;
    },
    onComplete: () => {
      menuIsAnimating = false;
    }
  })
  .to('nav.mobile', menuDuration, {
    ease: "power2.easeIn",
    webkitClipPath: clipOpen,
    clipPath: clipOpen
  })
  .to('nav.mobile ul.menu li', menuDuration, {
    ease: "linear",
    opacity: '1',
    stagger: 0.05
  }, ">");

const mainMenuClose = gsap
  .timeline({
    paused: true,
    onStart: () => {
      $('.menu-button, nav.mobile').removeClass('is-active');
      $('body').css('overflow', 'visible');
      menuIsAnimating = true;
      closeSubMenu($('ul.sub-menu.is-open')[0]);
    },
    onComplete: () => {
      menuIsAnimating = false;
    }
  })
  .to('nav.mobile ul.menu li', menuDuration, {
    ease: "linear",
    opacity: '0',
    stagger: 0.05
  })
  .to('nav.mobile', menuDuration, {
    ease: "power2.easeOut",
    webkitClipPath: clipClosed,
    clipPath: clipClosed
  }, ">");

const toggleMenu = (anim, duration, ease) => {
  const masterTimeline = gsap.fromTo(anim,
    duration, {
      progress: 0
    }, {
      ease: ease,
      progress: 1
    });
}

const openSubMenu = (subMenu) => {
  $(subMenu).addClass('is-open');
  gsap.timeline().fromTo(subMenu,
      0.35, {
        translateX: 0
      }, {
        ease: "power2.easeIn",
        translateX: "-100%"
      })
    .fromTo(['nav.mobile ul.menu li ul li'], 0.25, {
      opacity: 0
    }, {
      ease: "linear",
      opacity: '1',
      stagger: 0.05
    }, ">");
}

const closeSubMenu = (subMenu) => {
  gsap.timeline().fromTo(subMenu,
      0.35, {
        translateX: "-100%"
      }, {
        ease: "power2.easeOut",
        translateX: 0
      })
    .fromTo(['nav.mobile ul.menu li ul li'], 0.25, {
      opacity: 1
    }, {
      ease: "linear",
      opacity: '0',
      stagger: 0.05
    }, ">");
  $(subMenu).removeClass('is-open');
}

$('nav.mobile li.menu-item-has-children').each(function(i, e) {
  let title = $(this).find('a').first().text();
  let subMenu = $(this).find('ul.sub-menu');
  subMenu.prepend(`<li class="back">${title}</li>`);
});

$('nav.mobile li.menu-item-has-children a').not('nav.mobile li.menu-item-has-children li a').on('click', function(e) {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  let subMenu = $(this).parents('li').find('ul.sub-menu');

  openSubMenu(subMenu[0]);
});

$('nav.mobile ul.sub-menu .back').on('click', function(e) {
  let subMenu = $(this).parents('li').find('ul.sub-menu');

  closeSubMenu(subMenu[0]);
});

// Toggle menu
menuButton.on('click', function() {
  if (menuIsAnimating == false) {
    if (menuButton.hasClass('is-active')) {
      toggleMenu(mainMenuClose, 1, "power1.easeOut");
    } else {
      toggleMenu(mainMenuOpen, 1, "power1.easeIn");
    }
  }
});

$('nav.mobile a, header a').on('click', function() {
  if (menuButton.hasClass('is-active')) {
    toggleMenu(mainMenuClose, 1, "power1.easeOut");

  }
});