/**
 * fade.js
 * @author Cody Marcoux (Studio123)
 * @description This is the default fallback transition.
 */

const fadeTransition = {
  name: 'fade-transition',
  sync: false,
  leave({
    current
  }) {
    const done = this.async();
    gsap.fromTo([current.container, 'footer'], 0.4, {
      opacity: 1
    }, {
      opacity: 0,
      onComplete: () => {
        current.container.remove();
        window.scrollTo(0, 0);
        done();
      }
    }, 0);
  },
  enter({
    next
  }) {
    const done = this.async();
    gsap.fromTo([next.container, 'footer'], 0.4, {
      opacity: 0
    }, {
      opacity: 1,
      onComplete: () => {
        done();
      }
    }, 0);
  }
}