//----------------------------------------
// utils.js
// Author: @codymarcoux (Studio123)
//
// NOTE: Utils in this folder will be
//       concated before scripts.js
//----------------------------------------

// Fix vh units on mobile browsers
var vh = window.innerHeight * 0.01;
var vhMenu = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
document.documentElement.style.setProperty('--vhMenu', "".concat(vhMenu, "px"));
window.addEventListener('resize', function() {
  var vhMenu = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vhMenu', "".concat(vhMenu, "px"));
  if ($(window).width() >= 769) {
    var _vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', "".concat(_vh, "px"));
  }
});

const resizeMargin = (elem1, elem2) => {
  if ($(window).width() > 769) {
    $(elem1).css('margin-top', ($(elem2).height() * -1));
  } else {
    $(elem1).css('margin-top', 0);
  }
}